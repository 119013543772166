var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-overlay',{attrs:{"show":!_vm.closedTickets,"rounded":"sm"}},[_c('div',{staticClass:"invoice-register-table pt-1"},[_c('div',{staticClass:"float-left ir-btn-wrapper"},[_c('b-form-select',{staticClass:"closed-box1",attrs:{"id":"referral-type","options":_vm.ticketCategory},on:{"change":_vm.searchTableCategory},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block closed-box2",attrs:{"placeholder":"Search","type":"text"},on:{"keydown":_vm.searchTable},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),(_vm.closedTickets)?_c('vue-good-table',{staticClass:"mt-2",attrs:{"columns":_vm.columns,"rows":_vm.closedTickets,"sort-options":{
          enabled: true,
        },"search-options":{
          enabled: false,
          externalQuery: _vm.searchTerm },"pagination-options":{
          enabled: true,
          perPage:_vm.pageLength
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('span',[_c('router-link',{attrs:{"to":("/tickets/" + (props.row.id))}},[_vm._v(_vm._s(props.row.id))])],1):(props.column.field === 'user_email')?_c('span',[_c('router-link',{attrs:{"to":("/user/details/" + (props.row.uid))}},[_vm._v(_vm._s(props.row.user_email))])],1):(props.column.field === 'ticket_status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.ticket_status)}},[_vm._v(" "+_vm._s(_vm.ticketStatus(props.row))+" ")])],1):(props.column.field === 'created_at')?_c('span',[_vm._v(" "+_vm._s(_vm.getElapseDate(props.row.created_at))+" ")]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap mt-1"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10','25','50']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,245611698)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }